import { motion, useAnimationControls } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import StepForm from "./StepForm";
/**
 * MainStepForm controls the switching through steps via state
 * management.
 * ------
 * State:
 * ------
 * index - The step the user is currently on.
 * ------
 * Vars:
 * ------
 * length - holds the max number of steps to support tracking index in state.
 * ------
 * Functions:
 * ------
 * handlePrevious - controls changing state to previous step
 * handleNext - controls changing state to next step
 */
interface MainStepFormProps {
    // testProp: string;
    // optionalProp?: string;
    children?: ReactNode;
}
function MainStepForm(props:MainStepFormProps) {
    const defindex = document.location.pathname === "/prc" ? 1 : 0;
    const [index, setIndex] = useState(defindex);
    interface paramObject {
      [key: number]: string;
    }
    const defparam = document.location.pathname === "/prc" ? {1:"Insurance Enrollment"} : {1:""};
    const [urlParams] = useState<paramObject>(defparam)
    const length = 9;

    const handlePrevious = () => {
      //specific case for immunization skipping steps
      if(urlParams[1] === "Immunization" ||  urlParams[1] === "Insurance Enrollment"){

        switch(index){
          case 3:
            setIndex(1);
            return;
          case 5:
            setIndex(3);
            return;
          case 7:
            setIndex(5);
            return;
          default:
            const newIndex = index - 1;
            setIndex(newIndex < 0 ? length - 1 : newIndex);
        }

      } else if(urlParams[5] === "Child" && index === 7) {

          setIndex(5);

      } 
      /*else if(urlParams[1] === "Urgent Care Video Visits" && index === 3) {
        console.log("index is at", index)
        switch(index){
          case 3:
            setIndex(0);
            return;
          case 4:
            setIndex(3);
            return;
        }
        setIndex(0);
        return;
      }*/
      else {

        const newIndex = index - 1;
        setIndex(newIndex < 0 ? length - 1 : newIndex);
        
      }
        
    };
    //in handleNext we update the state object (urlParams)
    // when user is fully complete with steps urlParams obj
    // is used to build the link to Qure4u scheduling form
    const handleNext = (param:string) => {
        
        //check if url params contains the step already
        urlParams[index+1] = param;
        //console.log(urlParams);
        const newIndex = index + 1;
        // setPreviousIndex(index);
        setIndex(newIndex >= length ? 0 : newIndex);
        // console.log(urlParams)
    };

    //some fancy svg waves for animation
    const wavePath1 = "M0,96L80,85.3C160,75,320,53,480,64C640,75,800,117,960,133.3C1120,149,1280,139,1360,133.3L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z";
    const wavePath2 = "M0,256L80,250.7C160,245,320,235,480,197.3C640,160,800,96,960,64C1120,32,1280,32,1360,32L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z";
    const wavePath3 = "M0,192L80,170.7C160,149,320,107,480,80C640,53,800,43,960,32C1120,21,1280,11,1360,5.3L1440,0L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z";
    const wavePath4 = "M0,0L80,37.3C160,75,320,149,480,192C640,235,800,245,960,213.3C1120,181,1280,107,1360,69.3L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z";
    const circlePath1 = "M-250,0a250,250 0 1,0 500,0a250,250 0 1,0 -500,0";

    // setting up animation controls for animation with framer-motion
    const controls = useAnimationControls();
    const controlsTwo = useAnimationControls();
    const controlsThree = useAnimationControls();

    // useEffect hook to track changes in index and show bg animations
    // note: added controls and controlsTwo to dependancy to prevent ESlint from yelling at me
    useEffect(()=>{
      //useEffect is what functional components use to watch state changes
      // for rerendering the component pieces, in this case, firing animations when index changes
      controls.start({
        d: [
          wavePath1,
          wavePath2,
      ],
      });

      controlsTwo.start({
        d: [
          wavePath3,
          wavePath4,
      ],
      });

    }, [index, controls, controlsTwo])
   
    //jsx to return
    return (
      <div className="main-step-form h-full overflow-y-scroll overflow-x-hidden">
        <div className="md:block absolute hidden left-0 bottom-0 right-0 -z-10">
          <svg viewBox="0 0 1440 320" preserveAspectRatio="xMinYMin meet">
            <motion.path fill="#5a6771" fillOpacity="0.6" d={wavePath1}
                    animate={controls}
                    transition={{
                        duration: 1.6,
                        repeat: 1,
                        repeatType: "reverse",
                        ease: "easeInOut",
                    }}></motion.path>
          </svg>
        </div>
        <div className="md:block absolute hidden left-0 bottom-0 right-0 -z-10">
          <svg viewBox="0 0 1440 320" preserveAspectRatio="xMinYMin meet">
            <motion.path fill="#5a6771" fillOpacity="0.6" d={wavePath3}
                    animate={controlsTwo}
                    transition={{
                        duration: 1.4,
                        repeat: 1,
                        repeatType: "reverse",
                        ease: "easeInOut",
                    }}></motion.path>
          </svg>
        </div>
        <div className="md:block absolute hidden left-0 bottom-0 right-0 -z-10">
          <svg viewBox="-550 -350 500 500" preserveAspectRatio="xMinYMin meet">
            <motion.path fill="#5a6771" fillOpacity="0.2" d={circlePath1}
                    animate={controlsThree}
                    transition={{
                        duration: 1.2,
                        repeat: 1,
                        repeatType: "reverse",
                        ease: "easeInOut",
                    }}></motion.path>
          </svg>
        </div>
        <div className="md:block absolute hidden left-0 bottom-0 right-0 -z-10">
          <svg viewBox="-530 -340 500 500" preserveAspectRatio="xMinYMin meet">
            <motion.path fill="#5a6771" fillOpacity="0.15" d={circlePath1}
                    animate={controlsThree}
                    transition={{
                        duration: 1.2,
                        repeat: 1,
                        repeatType: "reverse",
                        ease: "easeInOut",
                    }}></motion.path>
          </svg>
        </div>
        { index >= 1 && (<button onClick={handlePrevious} className="fixed z-10 left-1 top-[100px] w-[40px] h-[40px] rounded-full sm:rounded-none sm:h-auto sm:w-auto p-0 sm:p-2 font-bold bg-chas-green text-white">{'<'} <span className="hidden hover:inline md:inline">Previous</span></button>)}
        <div className="h-full w-full p-2 md:p-16 "><StepForm urlParams={urlParams} index={index} callback={handleNext}></StepForm></div>
      </div>
    );
  }
  
  export default MainStepForm;
